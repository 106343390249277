import { render, staticRenderFns } from "./SCR_MSG_0021.vue?vue&type=template&id=459bac44&"
import script from "~/scriptData/ms/msg/SCR_MSG_0021.js?vue&type=script&lang=js&"
export * from "~/scriptData/ms/msg/SCR_MSG_0021.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports