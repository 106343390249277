
import '@/assets/scss/index.scss'
import { commonfunc } from '~/mixin/common'
import mbUtils from '~/scriptData/mb/utils/mbUtils'

export default {
  components: {
  },
  mixins: [commonfunc, mbUtils],
  data () {
    const customValid = (rule, value, callback) => {
      if (rule.field === 'rqstTy') {
        if (this.form.rqstTy === 'Z' && this.form.rqstMtr.length > 50) {
          callback(new Error('최대 50자 입력가능합니다.'))
        }
      } else if (rule.field === 'rcpmCntp1Etno') {
        if (!this.form.rcpmCntp1Etno.startsWith('010')) {
          callback(new Error('010으로 시작하는 휴대폰번호를 입력해 주세요.'))
        } else if (this.form.rcpmCntp1Etno.replace(/-/gi, '').length > 11) {
          callback(new Error('최대 11자 입력 가능합니다.'))
        } else if (this.form.rcpmCntp1Etno.replace(/-/gi, '').length < 11) {
          callback(new Error('유효한 휴대폰 번호를 입력해 주세요'))
        }
      } else if (rule.field === 'addrZip') {
        // 2024.01.11. 세 개의 필드에 하나의 검증 메세지를 띄워야 하므로 custom validator로 변경.
        if (!this.isNotEmpty(this.form.addrZip) || !this.isNotEmpty(this.form.addrBassEaddr)) {
          callback(new Error('주소를 입력해 주세요.'))
        } else if (!this.isNotEmpty(this.form.dtlEaddr)) {
          callback(new Error('상세주소를 입력해 주세요.'))
        }
      }

      callback()
    }
    return {
      titleNm: '배송지 등록',
      showPostPopup: false,
      srcComp: '',
      outerVisible: false,
      visible: false,
      labelPosition: 'top',
      rqstMtr: [],
      agrDc: {},
      form: {
        action: 'ins',
        mbMngNo: '',
        lsUpdrId: '',
        dvyaNm: '',
        rcpmEfnm: '',
        bassDvyaYn: '',
        rcpmCntp1Etno: '',
        addrZip: '',
        addrBassEaddr: '',
        dtlEaddr: '',
        rqstTy: '',
        // agrYn: 'N',
        rqstMtr: ''
      },
      bassDvyaYnDisabled: false,
      rules: {
        dvyaNm: [
          { required: true, message: '배송지명을 입력해 주세요.', trigger: 'blur' },
          { max: 9, message: '최대 9자 입력가능합니다.', trigger: 'blur' }
        ],
        rcpmEfnm: [
          { required: true, message: '수령인 이름을 입력해 주세요.', trigger: 'blur' },
          { pattern: /^[0-9가-힣a-zA-Z\s]+$/i, message: '올바른 이름을 입력해 주세요', trigger: 'blur' }
        ],
        rcpmCntp1Etno: [
          { required: true, message: '휴대폰번호를 입력해 주세요.', trigger: 'blur' },
          { validator: customValid, trigger: 'blur' }
        ],

        // addrZip: [
        //   { required: true, message: '주소를 입력해 주세요.', trigger: 'blur' }
        // ],
        // dtlEaddr: [
        //   { required: true, message: '상세주소를 입력해 주세요.', trigger: 'blur' }
        // ],
        // 2024.01.11. 상세주소가 검증되지 않는 오류 확인.
        // 세 개의 필드에 하나의 검증 메세지를 띄워야 하므로 custom validator로 변경.
        addrZip: [
          { required: true, message: '주소를 입력해 주세요.', trigger: 'blur' },
          { validator: customValid, trigger: 'blur' }
        ],

        rqstTy: [
          { validator: customValid, trigger: 'blur' }
        ],
        rqstMtr: [
          { max: 50, message: '최대 50자 입력가능합니다.', trigger: 'blur' }
        ]
      }
    }
  },
  // 우편번호
  head () {
    return {
      script: [{ src: '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js' }]
    }
  },
  computed: {
    formattedEmpno: {
      get () {
        return this.form.rcpmCntp1Etno
      },
      set (value) {
        this.form.rcpmCntp1Etno = value.slice(0, 13).replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')
      }
    }
  },
  watch: {
    outerVisible (data) {
      console.log(data)
      if (data) {
        if (this.form.action === 'ins') {
          this.titleNm = '배송지 등록'
          Object.keys(this.form).forEach((key) => {
            if (key !== 'action') {
              this.form[key] = ''
            }
          })
          if (this.bassDvyaYnDisabled) {
            this.form.bassDvyaYn = 'Y'
          } else {
            this.form.bassDvyaYn = 'N'
          }
          this.form.agrYn = 'N'
        } else if (this.form.action === 'upd') {
          this.titleNm = '배송지 수정'
          this.form.addrBassEaddr = this.unescape(this.form.addrBassEaddr)
          this.form.dtlEaddr = this.unescape(this.form.dtlEaddr)
        }
        if (this.$refs.form !== undefined) {
          this.$refs.form.clearValidate()
        }
      }
    }
  },
  async mounted () {
    this.form.rqstTy = ''
    const grpList = ['CD_00162', 'CD_00406']
    this.cmmnCdList = await this.selCmmnCd(grpList) // 공통 코드
    this.rqstMtr = this.cmmnCdFilter(this.cmmnCdList, 'CD_00162', '', 'S')
    const agrCode = this.cmmnCdFilter(this.cmmnCdList, 'CD_00406', '', '').filter((f) => { return f.cmmnCd === 'A0' })[0]
    this.agrDc = agrCode.cmmnCdDc.split('\n')
  },
  methods: {
    cncl () {
      this.$emit('cancleClick')
      this.outerVisible = false
    },
    async addrSearch (flag) { // 우편번호찾기
      this.showPostPopup = true // 화면표시를 해야 .el-dialog .el-dialog__body 엘리먼트를 가져올수 있다
      console.log(this.showPostPopup)
      // this.$nuxt.$loading.start() // 최초 우편번호찾기 팝업시 .el-dialog__body 가져오는 동안 로딩바 표시
      while (this.showPostPopup) {
        const dialogBody = document.querySelector('#postLayer .el-dialog .el-dialog__body')

        if (dialogBody) {
          // this.$nuxt.$loading.finish()
          dialogBody.style.height = '600px'
          document.querySelector('#postLayer .el-dialog').style.paddingBottom = '0px'
          this.loadDaumPost(dialogBody)
          break
        } else {
          await this.sleep(1000) // .el-dialog__body 가져올때까지 sleep
        }
      }
    },
    loadDaumPost (dialogBody) {
      // eslint-disable-next-line no-undef
      new daum.Postcode({
        oncomplete: (data) => {
          console.log('loadDaumPost', data)
          // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          let rnBassAddr = data.roadAddress || data.autoRoadAddress // 도로명기본주소
          // let lnmBassAddr = data.jibunAddress || data.autoJibunAddress // 지번기본주소

          // 건물명이 있는경우 빌딩주소를 도로명 주소에 추가 한다.
          if (data.buildingName !== '') {
            rnBassAddr += ' (' + data.buildingName + ')'
            // lnmBassAddr += ' (' + data.buildingName + ')'
          }

          this.$set(this.form, 'addrZip', data.zonecode) // 우편번호
          this.$set(this.form, 'addrBassEaddr', rnBassAddr) // 도로명기본주소
          // this.$set(this.form, 'lnmBassAddr', lnmBassAddr) // 지번기본주소

          dialogBody.style.display = 'none'
          this.showPostPopup = false
        },
        width: '100%',
        height: '100%',
        maxSuggestItems: 5
      }).embed(dialogBody)

      // iframe을 넣은 element를 보이게 한다.
      dialogBody.style.display = 'block'
    },
    dvyaSave (formName) {
      console.log(this.form)
      this.form.rcpmEfnm = this.form.rcpmEfnm.replace(/^\s+|\s+$/g, '')

      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.dvyaNm.length > 9) {
            this.$alert('배송지명은 최대 9자 입력가능합니다.')
            return false
          } else if (this.form.rcpmEfnm.length > 10) {
            this.$alert('수령인 이름은 최대 10자 입력가능합니다.')
            return false
          } else if (this.form.dtlEaddr.length > 50) {
            this.$alert('상세주소는 최대 50자 입력가능합니다.')
            return false
          } else {
            // if (this.form.agrYn !== 'Y') {
            //   this.$alert('정보수집에 대한 동의를 해주세요.')
            //   return false
            // } else {
            this.form.rqstMtr = this.form.rqstTy === 'Z' ? this.form.rqstMtr : ''
            this.$AxiosBuilder
              .data(this.form)
              .build()
              .post('/mb/mbc/saveDvya')
              .then((res) => {
                console.log(res)
                if (this.isNotEmpty(res.data.data)) {
                  const data = res.data.data
                  if (res.data.success) {
                    this.outerVisible = false
                    this.$emit('dvyaList', this.form)
                    this.$emit('popupCloseCallback', this.form)
                  } else {
                    this.$alert(data, '', {
                      confirmButtonText: '확인', /* 확인 */
                      dangerouslyUseHTMLString: true
                    })
                  }
                }
              })
              .catch((err) => {
                console.log('error')
                console.log(err)
              })
            // }
          }
        } else if (this.form.dvyaNm === '' || this.form.rcpmEfnm === '' || this.form.rcpmCntp1Etno === '' ||
          this.form.addrZip === '' || this.form.addrBassEaddr === '' || this.form.dtlEaddr === '') {
          this.$alert(this.$t('CM.WRITE_REQUIRED_FORM'))
          return false
        } else {
          console.log('validate fail!!')
          console.log(this.form)
          return false
        }
      })
    }
  }
}
